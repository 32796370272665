<template>
    <div>
        <div class="gap"></div>
        <div class="content">
            <div class="share">
                <img :src="require('../static/images/' + item + '.png')"  v-for="(item,index) in shareList" :key="index">
            </div>
            <div class="line"></div>
            <div>{{ $t('header.inc') }}</div>
        </div>
    </div>
</template>
<script setup>
    import { reactive } from 'vue';

    const shareList = reactive([
        'twitter','discord','gmail','github'
    ])
</script>
<style scoped>
    .gap{
        height: 280px;
    }
    .content{
        width: 100%;
        height: 280px;        
        color: rgba(255,255,255,0.75);
        position: absolute;
        bottom: 0;
        text-align: center;
        font-size: 12px;
        background: linear-gradient(90deg, #000000 0%, #1E313F 100%);
    }
    .share{
        width: calc(100% - 100px);
        max-width: 760px;
        padding-top: 57px;
        margin: 0 auto 57px;
        display: flex;
        justify-content: space-between;
    }
    .share img{
        width: 80px;
        height: 80px;
    }
    .line{
        width: calc(100% - 100px);
        max-width: 1200px;
        height: 1px;
        margin: 0 auto 30px;
        background-color: rgba(255,255,255,0.2);
    }
    @media screen and (max-width: 768px){
        .share img{
            width: 40px;
            height: 40px;
        }
    }
</style>