<template>
    <div class="header-lay">
       <div class="head-top-lay">
            <div class="left">
                <img @click="showMenuMobile=true" class="menu-icon mobile" src="../../static/images/menu.png" />
                <div @click="goHome()">
                    <img class="logo-notext" src="../../static/images/logo.png" />
                </div>
                <useMenu class="pc"></useMenu>
            </div>
            <div class="right">
                <div class="link-account" @click="connectWallet">
                    <img src="../../static/images/bnb.png" />
                    <span v-if="!showCurAccount">{{ $t('header.link') }}</span>
                    <span v-else>{{ showCurAccount }}</span>
                </div>
                <useLanguage class="use-language"></useLanguage>
            </div>
        </div>
        <!-- 手机版菜单 -->
        <useMenuMobile v-if="showMenuMobile" @close="hideMenuMobile"></useMenuMobile>
        <!-- 绑定地址弹窗 -->
        <useDialog v-if="showInviteDialog" @close="hideInviteDialog"></useDialog>
    </div>
</template>

<script>
export default {
    name: 'headers',
    props:{
        homeBg: {
            type: String,
            default: 'home_bg.png',
        },
        text:{
            type: String,
            default: ''
        }
    },
    data(){
        return{
            languageList:[
                {label: 'English',value: 'en'},
                {label: '简体中文',value: 'cn'},
                {label: '繁體中文',value: 'tc'}
            ],
            showLanguage: false,
            language: ''
        }
    },
    watch:{
        showLanguage(val){
            const html = document.getElementsByTagName('html')[0]
            if(val){
                html.style.overflow = 'hidden'
            }else{
                html.style.overflow = 'auto';
            }
        }
    },
    async mounted() {
		this.getLanguage()
    },
    methods:{
        goHome(){
            this.$router.push({
                path: '/'
            })
        },
        getLanguage(){
            this.language = localStorage.getItem('locale') || 'en' ;
        },
        handleLanguage(value){
            this.$i18n.locale = value
            this.showLanguage = false;
            localStorage.setItem('locale',value);
            this.language = value
        }
    }
}
</script>

<script setup>
import {call, connect, send} from "@/contract/web3-util";
import {walletStore} from "@/store/wallet";
import {memberStore} from "@/store/member";
import {webStore} from "@/store/web";
import {computed, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import * as Web3Util from "@/contract/web3-util";
import Web3 from "web3";
import i18n from "@/i18n";
import useLanguage from './language'
import useMenu from './menu'
import useMenuMobile from './menuMobild'
import useDialog from './dialog'
import {ElMessage} from "element-plus";
import {end, start} from "@/api/loading";
const wStore = walletStore()
const mStore = memberStore()
const wbStore = webStore()

const route = useRoute()

const showInviteDialog = ref(false)

const showCurAccount = computed(() => {
	if (wStore.curAccount) {
		return wStore.curAccount.substring(0, 6) + '...' + wStore.curAccount.substring(wStore.curAccount.length - 4)
	}

	return ''
})

//是否显示手机端菜单
const showMenuMobile = ref(false);

const hideMenuMobile = () => {
    showMenuMobile.value = false
}

const hideInviteDialog = () => {
  showInviteDialog.value = false
}

onMounted(() => {
	const params = route.query
	if (params && params.invite) {
		setInvite(params.invite)
	}
})

function connectWallet() {
	connect()
}
function setInvite(invite) {
	mStore.setParamParentAddress(invite)
	queryParent(invite)
}
function queryParent(parent) {
	const that = this
	Web3Util.call('stake-v2', 'members', [parent]).then(function(result) {
		if (result && result.uid > 0) {
			mStore.setParentAddress(parent)
		}
	})
}

const mInfo = computed(()=>{
  return mStore.memberInfo
})
watch(mInfo, (newVal, oldVal) =>{
  if (newVal && newVal.uid - 0 === 0) {
    showInviteDialog.value = true
  } else {
    showInviteDialog.value = false
  }
})
</script>

<style scoped>
    .header-lay{
        width: 100%;
        height: 80px;
        position: fixed;
        top: 0px;
        z-index: 1002;
        background: #FBFCFE;
    }
    .head-top-lay{
        width: calc(100% - 30px);
        height: 100%;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .head-top-lay .left{
        display: flex;
        align-items: center;
    }
    .head-top-lay .left .menu-icon{
        width: 30px;
        height: 30px;
        margin-right: 10px;
        cursor: pointer;
    }
    .head-top-lay .logo-notext{
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    .head-top-lay .right{
        display: flex;
        align-items: center;
    } 
    .head-top-lay .right .link-account{
        height: 40px;
        padding: 0 15px;
        background-color: #F2F2F9;
        color: black;
        border-radius: 25px;
        display: flex;
        align-items: center;
        font-size: 14px;
        user-select: none;
        font-weight: bold;
        color: #8C66FC;
        cursor: pointer;
    }
    .head-top-lay .right .link-account img{
        width: 23px;
        height: 23px;
        margin-right: 5px;
    }
    .use-language{
        margin-left: 20px;
        cursor: pointer;
    }
    .pc{
        display: flex;
    }
    .mobile{
        display: none;
    }
    @media screen and (max-width: 768px) {
        .pc{
            display: none
        }
        .mobile{
            display: flex;
        }
        .header-lay{
            height: 70px;
        }
        .head-top-lay .logo-notext{
            width: 30px;
            height: 30px;
        }
        .use-language{
            margin-left: 10px;
        }
    }
</style>
