import {defineStore} from "pinia";
import * as Web3Util from "@/contract/web3-util";
import Web3 from "web3";
import {walletStore} from "@/store/wallet";

const wStore = walletStore()

export const memberStore = defineStore('member', {
	state: () => {
		return {
			memberInfo: {
				uid: 0,               // uid
				shareTotal: 0,         // 分享的总人数
				parent: '',              // 父级地址

				bonusWithdraw: 0,      // 已提取奖金
				topBonusWithdraw: 0,    // 已提取TOP奖金
				buyTopUSDTAmount: 0,    // 购买TOP支付的USDT总金额
				buyTopTopAmount: 0     // 购买TOP得到的TOP总金额
			},
			paramParentAddress: '', // 分享链接中带的父级地址
			parentAddress: '' // 显示的父级地址
		}
	},
	getters: {
		getMemberInfo(state) {
			return state.memberInfo
		},
		getParentAddress(state) {
			return state.parentAddress
		}
	},
	actions: {
		setMemberInfo(_memberInfo) {
			this.memberInfo = _memberInfo
		},
		setParamParentAddress(_paramParentAddress) {
			this.paramParentAddress = _paramParentAddress
		},
		setParentAddress(_parentAddress) {
			this.parentAddress = _parentAddress
		},
		queryMemberInfo() {
			if (wStore.curAccount) {
				const that = this
				Web3Util.call('stake-v2', 'members', [wStore.curAccount]).then(function (result) {
					if (result) {
						that.memberInfo = {
							uid: result.uid - 0,
							parent: result.parent,
							shareTotal: result.shareTotal - 0,
							bonusWithdraw: Web3.utils.fromWei(result.bonusWithdraw) - 0,
							topBonusWithdraw: Web3.utils.fromWei(result.topBonusWithdraw) - 0,
							buyTopUSDTAmount: Web3.utils.fromWei(result.buyTopUSDTAmount) - 0,
							buyTopTopAmount: Web3.utils.fromWei(result.buyTopTopAmount) - 0,
						}
					}
				})
			}
		}
	}
})
