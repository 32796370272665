<template>
    <div class="dialog">
        <div>
            <div class="title">
                {{ $t('header.bindTitle') }}
            </div>

          <input v-if="showParentAddress" :value="showParentAddress" disabled/>
          <input :placeholder="$t('header.bindPla')" v-else v-model="inputParentAddress"/>
            <button @click="bind">{{ $t('header.bind') }}</button>
        </div>
    </div>
</template>
<script setup>
import {ElMessage} from "element-plus";
import i18n from "@/i18n";
import Web3 from "web3";
import {call, send} from "@/contract/web3-util";
import {end, start} from "@/api/loading";
import {computed, defineEmits, onMounted, ref} from "vue";

import {walletStore} from "@/store/wallet";
import {memberStore} from "@/store/member";
import {webStore} from "@/store/web";
import {useRoute} from "vue-router";

const wStore = walletStore()
const mStore = memberStore()
const wbStore = webStore()

const route = useRoute()

const inputParentAddress = ref('')

const emits = defineEmits('close')

const showParentAddress = computed(() => {
  if (mStore.parentAddress) {
    return mStore.parentAddress.substring(0, 18) + '...' + mStore.parentAddress.substring(mStore.parentAddress.length - 12)
  }

  return ''
})

const close = () => {
  emits('close')
}

const bind = async () => {
  if (!wStore.curAccount) {
    ElMessage({
      message: i18n.global.t('alert.connectWallet'),
      type: 'warning'
    })

    return
  }

  let p
  if (!mStore.parentAddress) {
    if (!inputParentAddress.value) {
      ElMessage({
        message: i18n.global.t('alert.inputParent'),
        type: 'warning'
      })

      return
    }

    if (!Web3.utils.isAddress(inputParentAddress.value)) {
      ElMessage({
        message: i18n.global.t('alert.addressError'),
        type: 'warning'
      })

      return
    }

    const parent = await call('stake-v2', 'members', [inputParentAddress.value])
    if (parent.uid === 0) {
      ElMessage({
        message: i18n.global.t('alert.parentError'),
        type: 'warning'
      })

      return
    }

    p = inputParentAddress.value
  } else {
    p = mStore.parentAddress
  }

  start()
  send('stake-v2', 'bind', [p]).then(function (receipt) {
    if (receipt.status) {
      ElMessage({
        message: i18n.global.t('alert.success'),
        type: 'success'
      })

      close()
      mStore.queryMemberInfo()
    } else {
      ElMessage({
        message: i18n.global.t('alert.failed'),
        type: 'error'
      })
    }
    end()
  }).catch(function (error) {
    console.error(error)
    end()
  })
}


</script>
<style scoped>
     .dialog{
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, .7);
        position: fixed;
        z-index: 1010;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dialog > div{
        width: calc(100% - 100px);
        max-width: 300px;
        padding: 24px;
        background: #E9FFF7;
        box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.1);
        border-radius: 24px 24px 24px 24px;
        color: black;
        display: flex;
        flex-direction: column;
    }
    .dialog img{
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
    .dialog .title{
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .dialog input{
        margin: 25px 0;
        height: 40px;
        padding: 0 8px;
        border-radius: 8px;
        border: 1px solid #CCCCCC;
    }
    .dialog button{
        height: 40px;
        font-weight: bold;
        color: white;
        background: linear-gradient(90deg, #8C66FC 0%, #0274F1 100%);
        border-radius: 8px;
    }
</style>